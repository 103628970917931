import {getService as service} from './angular_helpers.js';
import {getData} from './script_data.js';

let _i18n;

export function initI18n() {
  _i18n = getData('/neue/i18n');
}

function activateFonts(fontIds) {
  const FirstMileService = service('FirstMileService');
  const NotificationService = service('NotificationService');
  const SyncService = service('SyncService');

  SyncService.addSyncedVariations(fontIds).then(response => {
    if (response.data.added_font_ids.length < 1) {
      return NotificationService.error(_i18n['neue.browse.family_card']['error_occurred_activating_fonts']);
    }

    FirstMileService.shouldShowFontActivationDialog().then(shouldShow => {
      if (shouldShow) {
        return FirstMileService.showFontActivationDialog();
      }

      return NotificationService.success(getActivatedMessage(response.data.added_font_ids.length));
    });
  });
}

function deactivateFonts(fontIds) {
  const NotificationService = service('NotificationService');
  const SyncService = service('SyncService');

  SyncService.removeSyncedVariations(fontIds).then(response => {
    if (response.data.removed_font_ids.length !== fontIds.length) {
      return NotificationService.error(_i18n['neue.browse.family_card']['error_occurred_deactivating_some_fonts']);
    }

    NotificationService.success(getDeactivatedMessage(response.data.removed_font_ids.length));
  });
}

function getActivatedMessage(count) {
  var pluralValue = count === 1 ? 'one' : 'other';
  return _i18n['neue.browse.family_card']['fonts_activated_action'][pluralValue].replace('%{count}', count);
}

function getDeactivatedMessage(count) {
  var pluralValue = count === 1 ? 'one' : 'other';
  return _i18n['neue.browse.family_card']['fonts_deactivated_action'][pluralValue].replace('%{count}', count);
}

export function handleActivationEvent(event) {
  const AuthenticationService = service('AuthenticationService');
  const fontIds = event.detail.fontIds;

  if (event.detail.isActivated) {
    AuthenticationService.handleAuthenticationWithPostLoginAction('activate_fonts', {
      font_ids: fontIds
    }).then(() => activateFonts(fontIds));
  } else {
    deactivateFonts(fontIds);
  }
}

